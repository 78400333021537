import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Seo, Text } from 'components';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useRouter } from 'apis/history';
import Header, { HEADER_HEIGHT_MOBILE } from 'components/Header';
import { usePageView } from 'utils/hooks';

interface OnBordingCallProps {}

const StyledWidgetContainer = styled.div`
  margin-bottom: 1rem;
`;

const OnBordingCall: FC<OnBordingCallProps> = () => {
  const { goToOnBoardingCheckout } = useRouter();

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: e => {
      sessionStorage.setItem('onboardingBooked', 'true');
      goToOnBoardingCheckout();
    },
  });

  usePageView();

  const emailData = sessionStorage.getItem('userEmail');
  return (
    <>
      <Seo />
      <Header logoVariant="left" />
      <InlineWidget
        prefill={{ email: ` ${emailData}` }}
        url="https://calendly.com/d/2gv-zzn-9qz/testing-event"
      />
    </>
  );
};
export default OnBordingCall;
